<template>
    <div class="app-body">
    <!-- <div class="app-body" v-if="reload" :key="$route.fullPath"> -->
        <div class="video-area">
            <div class="video-detail">
                <van-image v-if="video.status" fit="cover" :src="video.poster" @click="videoPlay"/>
                <video v-if="!video.status" controls controlslist="nodownload" preload="auto" ref="videoPlayer" :src="video.src"></video>
            </div>
            <div class="title">
                {{ video.title }}
            </div>
            <div class="limit">
                {{ video.playtime | formatTime }}
            </div>
        </div>
        <div class="video-list-area">
            <div class="nav-title">更多课程</div>
            <div class="list-area">
                <div v-for="(row, index) in list.data" :key="index" class="list-row" @click="toDetail(row.id)">
                    <van-image fit="cover" lazy-load :src="row.cover"/>
                    <div class="text-area">
                        <div class="title">
                            {{ row.title }}
                        </div>
                        <div class="limit">
                            {{ row.playtime | formatTime }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 13vw"></div>
        <!-- <foot></foot> -->
    </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload, Notify } from 'vant';
import Foot from "@/components/Foot";
import { topicsDetail } from "@/api/topics";
import moment from 'moment';

Vue.use(Lazyload);

export default {
    components: { 
        Foot
    },
    filters: {
        formatTime(seconds) {
            return seconds > 0 ? moment.utc(seconds * 1000).format('HH:mm:ss') : '';
        }
    },
    data() {
        return {
            reload: true,
            id: this.$route.query.id,
            video: {
                status: true,
                title: '',
                playtime: 0,
                poster: '',
                src: '',
            },
            list: {
                loading: false,
                data: [],
                page: 1,
                limit: 6,
                total: 1,
            },
        };
    },
    created() {
        // console.log('create');
    },
    mounted(){
        // console.log('mounted');
        // console.log(this.id);
        this.onLoad();
    },
    methods: {
        async onLoad() {
            // console.log(isinit);
            // if (isinit) {
            //     this.list.pullloading = true;
            //     this.list.page = 0;
            // } else {
            //     this.list.loading = true;
            // }

            // this.list.page += 1;
            try {
                const { data } = await topicsDetail({
                    id: this.id,
                    // page: this.list.page,
                    // limit: this.list.limit 
                });
    
                const { detail, list } = data
    
                this.video.title = detail.title;
                this.video.playtime = detail.playtime;
                this.video.poster = detail.cover;
                this.video.src = detail.video;

                this.list.data = list;
    
                // this.list.total = data.total;
    
                // if (isinit) {
                    // this.list.data = data.rows;
                //     this.list.pullloading = false;
                // } else {
                //     this.list.data.push(...data.rows);
                //     this.list.loading = false;
                // }
            } catch (e) {
                // console.log(e.ms);
                // Notify({ 
                //     type: 'danger', 
                //     message: e.message
                // });
            }
        },
        videoPlay(e) {
            let that = this;
            this.video.status = false;
            setTimeout(() => {
                that.$refs.videoPlayer.play();
            }, 1)
        },
        toDetail(id) {
            // let that = this;
            // this.id = id;
            // this.reload = false;
            // setTimeout(() => {
            //     that.reload = true;
            this.video.status = true;
            //     this.onLoad();
            // }, 100)
            this.$router.replace({
                path: '/topics_detail',
                query: {
                    id: id
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.app-body {
    background-color: #fafafa;
    // padding-top: 1.9vh;
    height: 100%;
    font-size: 3.7vw;

    video {
        width: 100%;
        height: calc(100vw / 16 * 9);
    }
}
.video-area {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2vh;
    padding-bottom: 2vh;
    // font-size: 1.9vh;
    // font-size: 3.7vw;
    .van-image {
        height: calc(100vw / 16 * 9);
        width: 100%;
        position: relative;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url('../../assets/img/icon_bofang_1@2x.png') no-repeat center center;
            // background-size: auto 3.2vh;
            background-size: auto 10vw;
            background-color: rgba(0,0,0,.4);
            z-index: 1;
            // box-shadow: inset 0 10vh 8vh -8vh rgba(0,0,0,.8), inset 0 -10vh 8vh -8vh rgba(0,0,0,.8);
            box-shadow: inset 0 13vw 8vw -8vw rgba(0, 0, 0, .8), inset 0 -13vw 8vw -8vw rgba(0, 0, 0, .8);
        }
    }
    .title {
        padding: 0 2vh;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
        min-height: 4.9vw;
    }
    .limit {
        padding: 0 2vh;
        // background: url('../../assets/img/icon_shichang@2x.png') no-repeat 2vh center;
        // background: url('../../assets/img/icon_shichang@2x.png') no-repeat 2vw center;
        background: url('../../assets/img/icon_shichang@2x.png') no-repeat 2vw -.5vw;
        background-size: auto 100%;
        color: #999999;
        // padding-left: 4.5vh;
        padding-left: 7.5vw;
        min-height: 4.9vw;
    }
}
.video-list-area {
    margin-top: 1.9vh;
    padding: 0 1.9vh;
    background-color: #fff;
    padding-top: 1.9vh;
    .nav-title {
        border-left: .5vh solid #5380ff;
        font-weight: bold;
        // margin: 0 1.9vh 1.9vh;
        padding-left: 1vh;
        // padding: 1.9vh 0vh 1.9vh 1vh;
        margin-bottom: 1.9vh;
    }
    .list-area {
        padding-bottom: 1.9vh;
        .list-row {
            // width: calc((100vw - 1.9vh*3) / 2) ;
            // height: calc((100vw - 1.9vh*3) / 2.3) ;
            height: calc((100vw - 1.9vh*3) / 4) ;
            overflow: hidden;
            // border-radius: 1vh;
            background-color: #fff;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            gap: 1vh;
            // padding-bottom: 1vh;
            // box-shadow: 0 0.3vh 1vh #eeeeee;
            // font-size: 1.9vh;
            margin-bottom: 1.9vh;
            &:last-child {
                margin-bottom: 0;
            }
            .van-image {
                height: 100%;
                width: 45%;
                position: relative;
                border-radius: 1vh;
                overflow: hidden;
                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: url('../../assets/img/icon_bofang_1@2x.png') no-repeat center center;
                    // background-size: auto 3.2vh;
                    background-size: auto 6vw;
                    background-color: rgba(0,0,0,.4);
                    z-index: 1;
                }
            }
            .text-area {
                width: 55%;
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: center;
                gap: 3vh;
                .title {
                    padding: 0 1vh;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .limit {
                    padding: 0 1vh;
                    // background: url('../../assets/img/icon_shichang@2x.png') no-repeat 1vh center;
                    background: url('../../assets/img/icon_shichang@2x.png') no-repeat 1vw center;
                    background-size: auto 100%;
                    color: #999999;
                    // padding-left: 3.5vh;
                    padding-left: 6.4vw;
                }
            }
        }
    }
}

</style>
